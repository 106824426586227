import React, { useContext, useEffect, useState } from 'react'
import Header from '../comman/Header'
import { ProjectContext } from '../../ProjectContext'
import axios from 'axios'
import { Link } from 'react-router-dom'

export default function Withdrawal() {
    const {
        account,
        toastSuccess, dbbalance,
        tokenrate,
        toastError, formatAddress,
        getuserDB,
        connectMetamask,
    } = useContext(ProjectContext)
    const [amount, setamount] = useState(0);
    const [rcv_amount, setrcv_amount] = useState(0);
    const [fees, setfees] = useState(0);
    const [total_tokens, settotal_tokens] = useState(0);
    const [trdone, settrdone] = useState(false);

    const withdraw = async () => {
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!amount || amount < 0) {
            toastError("Enter amount");
            settrdone(false);
            return;
        }
        if (amount < 20) {
            toastError("Min $20 required");
            settrdone(false);
            return;
        }
        if (Number(amount) > dbbalance) {
            toastError("Insufficient Balance");
            settrdone(false);
            return;
        }
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "insert",
                key: process.env.REACT_APP_KEY,
                address: account,
                amount: amount,
                token: total_tokens,
                fee: fees,//admin
                t_rcv: rcv_amount,
                rate: tokenrate,
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess(res.data.data);
            });
        getuserDB();
        settrdone(false);
    }
    useEffect(() => {
        var tmt = amount / tokenrate;
        var tmtfee = (tmt * 10) / 100;
        settotal_tokens(tmt);
        setfees(tmtfee);
        setrcv_amount(tmt - tmtfee);
    }, [amount, tokenrate]);


    return (
        <>
            <div className="main-wrapper">
                <Header />
                {/* <LeftSidebar /> */}
                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/dashboard" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white" /></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Withdrawal</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
                                        <form action="#">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-8 mb-3 d-flex justify-content-between align-items-end">
                                                    <div className="form-group">
                                                        <div className='d-flex align-items-center'>
                                                            <figure className="avatar me-3"><img src="assets/images/user-12.png" alt="image" className="shadow-sm rounded-circle w50" /></figure>
                                                            <div className=''>
                                                                <label className="mont-font fw-600 font-xsss">Address</label>
                                                                <p>{formatAddress(account)} <i className='ti-clipboard' /></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-grid'>
                                                        <label className="mont-font fw-600 font-xsss">Balance</label>
                                                        <span className='bg-current rounded-xl p-0 p-2 lh-24 w100 text-center text-white'> ${dbbalance}</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss">Enter Amount</label>
                                                        <input type="text" name="comment-name" className="form-control" value={amount}
                                                            onChange={(e) =>
                                                                setamount(e.target.value)
                                                            } />
                                                    </div>
                                                    <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Rate</span> <span> $ {tokenrate}</span></div>
                                                    <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Total Token</span> <span>{total_tokens}{" "} LMC</span></div>
                                                    <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Fees</span> <span>{fees}{" "} LMC (10%)</span></div>
                                                    <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Received Amount</span> <span>{rcv_amount}{" "}
                                                        LMC</span></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-0 mt-2 ps-0 d-flex justify-content-center">
                                                <a type='button' className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={() => withdraw()}>Withdrawal</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* <div class="card w-100 border-0 p-2"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
