import React, { useContext, useEffect, useState } from 'react'
import Header from '../comman/Header'
import LeftSidebar from '../comman/LeftSidebar'
import Table from './Table'
import Pagination from '../comman/Pagination'
import { ProjectContext } from '../../ProjectContext'
import axios from 'axios';
import { Link } from 'react-router-dom'

export default function WithdrawalHistory() {
    const { showmenu, setshowmenu, account, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [datap, setdatap] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "getbyid",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    const getpData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "getpbyid",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdatap(res.data.data);
            });
    };

    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);
    useEffect(() => {
        getData();
        getpData()
    }, [account]);

    return (
        <>
            <div className="main-wrapper">
                <Header />
                {/* <LeftSidebar /> */}
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{ maxWidth: '100%' }}>
                            <div className="middle-wrap-2">
                                <div className="card-body px-4 w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3">
                                    <div className='d-flex'>
                                        <Link to="/dashboard" className="d-inline-block"><i className="ti-arrow-left font-sm text-white" /></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0">Withdrawals</h4>
                                    </div>

                                </div>
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4 overflow-auto">
                                    <div className="card-body px-lg-5 px-4 w-100 border-0 mb-0">
                                        {datap !== null ?
                                            <>
                                                <div className="table-container mt-5">
                                                    <table id="mytable" className="table align-middle mb-0">
                                                        <thead className="">
                                                            <tr className="header-row">
                                                                <th>#</th>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Rate</th>
                                                                <th>Fee</th>
                                                                <th>Received Amount</th>
                                                                {/* <th>Hash</th> */}
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {datap.map((ele, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {i + 1}
                                                                        </td>
                                                                        <td > {ele.datetime}
                                                                        </td>
                                                                        <td >
                                                                            $ {ele.amount}
                                                                        </td>
                                                                        <td >
                                                                            $ {ele.rate} 
                                                                        </td>
                                                                        <td >
                                                                            {ele.fee} {process.env.REACT_APP_TICKER} (5%)
                                                                        </td>
                                                                        <td >
                                                                            {ele.t_rcv} {process.env.REACT_APP_TICKER}
                                                                        </td>
                                                                        <td className='text-warning'> Pending
                                                                        </td>

                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </> : ''}
                                        <div className="table-container mt-5">
                                            <table id="mytable" className="table align-middle mb-0">
                                                <thead className="">
                                                    <tr className="header-row">
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Amount</th>
                                                        <th>Rate</th>
                                                        <th>Fee</th>
                                                        <th>Received Amount</th>
                                                        {/* <th>Hash</th> */}
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="text-center">
                                                        {isLoading ? "Data is loading" : ""}
                                                    </tr>
                                                    {!isLoading ? (
                                                        !data.length ? (
                                                            <tr className="text-center">
                                                                <td className="text-center" colSpan={7}>
                                                                    <span className="w-100">No data found</span>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            ""
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                    {data.map((ele, i) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {i + 1}
                                                                </td>
                                                                <td > {ele.datetime}
                                                                </td>
                                                                <td >
                                                                    $ {ele.amount}
                                                                </td>
                                                                <td >
                                                                   $ {ele.rate}
                                                                </td>
                                                                <td >
                                                                    {ele.fee} {process.env.REACT_APP_TICKER} (5%)
                                                                </td>
                                                                <td >
                                                                    {ele.t_rcv} {process.env.REACT_APP_TICKER}
                                                                </td>
                                                                <td className='text-success'> Success
                                                                </td>

                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            <Pagination
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageSize={pageSize}
                                                setPageSize={setPageSize}
                                                pages={pages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
