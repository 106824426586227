import './App.css';
import Dashboard from './Components/Dashboard/Dashboard';
import Reward from './Components/Dashboard/Reward';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Stake from './Components/Dashboard/Stake';
import Withdrawal from './Components/Dashboard/Withdrawal';
import Table from './Components/Dashboard/Table';
import Staking from './Components/Dashboard/Staking';
import BlastIncome from './Components/Dashboard/BlastIncome';
import FasterIncome from './Components/Dashboard/FasterIncome';
import AncestoryIncome from './Components/Dashboard/AncestoryIncome';
import ExpressionIncome from './Components/Dashboard/ExpressionIncome';
import BelieveIncome from './Components/Dashboard/BelieveIncome';
import Profile from './Components/Dashboard/Profile';
import Team from './Components/Dashboard/Team';
import WithdrawalHistory from './Components/Dashboard/WithdrawalHistory';
import Login from './Components/Login';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/reward" element={<Reward />} />
          <Route path="/stake/:link_ref_address" element={<Stake />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/blast" element={<BlastIncome />} />
          <Route path="/faster" element={<FasterIncome />} />
          <Route path="/ancestory" element={<AncestoryIncome />} />
          <Route path="/expression" element={<ExpressionIncome />} />
          <Route path="/believe" element={<BelieveIncome />} />
          <Route path="/withdrawal" element={<Withdrawal />} />
          <Route path="/withdrawal-history" element={<WithdrawalHistory />} />
          <Route path="/team" element={<Team />} />
          <Route path="/table" element={<Table />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
