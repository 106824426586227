import React, { useContext } from 'react'
import Header from '../comman/Header'
import CountdownTimer from './CountdownTimer'
import { Link } from 'react-router-dom'
import { ProjectContext } from '../../ProjectContext'

export default function Dashboard() {
    const { dbuser, account, copyaddress } = useContext(ProjectContext)

    return (
        <>
            <div className="main-wrapper">
                <Header />
                {/* <LeftSidebar /> */}
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">

                        <div className="middle-sidebar-left pe-0">
                            <div className="row justify-content-center">
                                <div className='col-5'>

                                </div>
                                <div className="col-xl-12 ">
                                    <h2 className="font-xl d-block mb-2 text-grey-900 fw-700">LikeME Account</h2>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card w-100 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        {/* <img src="assets/images/bg-2.png" alt="image" className="w-100" /> */}
                                                        <div className="card-body d-block w-100 p-4 text-center">
                                                            <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1"><img src="assets/images/user_2.png" alt="image" className="float-right p-1 bg-white rounded-circle w-100" /></figure>
                                                            <div className="clearfix" />
                                                            <h4 className="fw-700 font-xss mt-3 mb-0">LikeME  </h4>
                                                            <ul className="d-flex align-items-center justify-content-center mt-1">
                                                                {dbuser ? dbuser.rank_number === 0 ? '' :
                                                                    <li className="m-1" title={`${dbuser.rank} Rank `}><img src={`assets/img/ranks/${dbuser.rank_number}.svg`} alt="icon" style={{ "width": "50px", "height": "auto" }} /></li> : ''}
                                                                {dbuser ? dbuser.believe_rank_number === 0 ? '' :
                                                                    <li className="m-1" title={`${dbuser.believe_rank} Rank`}><img src={`assets/img/ranks_believe/${dbuser.believe_rank_number}.svg`} style={{ "width": "50px", "height": "auto" }} alt="icon" /></li> : ''}

                                                            </ul>

                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6 ps-lg-5">
                                                        <h2 className="font-xl d-block mb-2 text-grey-900 fw-700" >
                                                            {/* <span class="font-xssss fw-600 text-grey-500 d-block mb-2 ms-1">Welcome back</span>  */}
                                                            My Account</h2>
                                                        <p className="font-xssss fw-500 text-grey-500 lh-26" onClick={() => copyaddress(account)}>{account} <i className='ti-clipboard' /></p>
                                                        <h2 className="font-xl d-block mb-2 text-grey-900 fw-700">
                                                            My Mentor</h2>
                                                        <p className="font-xssss fw-500 text-grey-500 lh-26" onClick={() => copyaddress(dbuser !== null
                                                            ? dbuser.ref_address
                                                            : "-")}>{dbuser !== null
                                                                ? dbuser.ref_address
                                                                : "-"} <i className='ti-clipboard' /></p>

                                                        <div className="d-flex justify-content-between">
                                                            {dbuser !== null
                                                                ? dbuser.restake ? <Link to='/stake' className="btn w150 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Restake </Link> : ''
                                                                : <Link to='/stake' className="btn w150 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Stake </Link>}

                                                            <a type='button' className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss" onClick={() => copyaddress(`${process.env.REACT_APP_LINK}stake/${account}`)}>FOLLOW LINK</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <h2 className="font-xl d-block mb-2 text-grey-900 fw-700">LikeME Rewards</h2>
                                            <div className="card w-100 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                                                <div className="row align-items-center">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-lg-4 p-0">
                                                                <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#e5f6ff' }}>
                                                                    <div className="card-body d-flex p-0">
                                                                        <img src="assets/img/blast_1.svg" className="me-3" style={{ width: "50px" }} alt="" />
                                                                        <h4 className="text-primary font-xl fw-700">$ {dbuser ? Number(dbuser.staking_inc).toFixed(2) : 0}   <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Blast</span></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 p-0">
                                                                <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                                    <div className="card-body d-flex p-0">
                                                                        <img src="assets/img/faster1.svg" className="me-3" style={{ width: "50px" }} alt="" />
                                                                        <h4 className="text-secondary font-xl fw-700">$ {dbuser ? Number(dbuser.level_inc).toFixed(2) : 0}   <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Faster</span></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 p-0">
                                                                <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#e2f6e9' }}>
                                                                    <div className="card-body d-flex p-0">
                                                                        <img src="assets/img/ancestry_1.svg" className="me-3" style={{ width: "50px" }} alt="" />
                                                                        <h4 className="text-success font-xl fw-700">$ {dbuser ? Number(dbuser.roi_inc).toFixed(2) : 0}   <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Ancestry</span></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 p-0">
                                                                <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#fff0e9' }}>
                                                                    <div className="card-body d-flex p-0">
                                                                        <img src="assets/img/expression_1.svg" className="me-3" style={{ width: "50px" }} alt="" />
                                                                        <h4 className="text-warning font-xl fw-700">$ {dbuser ? Number(dbuser.expression_inc).toFixed(2) : 0}   <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Expression</span></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 p-0">
                                                                <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#e5f6ff' }}>
                                                                    <div className="card-body d-flex p-0">
                                                                        <img src="assets/img/believe_1.svg" className="me-3" style={{ width: "50px" }} alt="" />
                                                                        <h4 className="text-primary font-xl fw-700">$ {dbuser ? Number(dbuser.believe_inc).toFixed(2) : 0}   <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Believe</span></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 p-0">
                                                                <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                                    <div className="card-body d-flex p-0">
                                                                        <img src="assets/img/achievement_1.svg" className="me-3" style={{ width: "50px" }} alt="" />
                                                                        <h4 className="text-secondary font-xl fw-700">$ {dbuser ? Number(dbuser.believe_nft_inc).toFixed(2) : 0}   <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Achievement</span></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-xl-12">
                                    <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 rounded-xxl">
                                        <div className="card-body p-0">
                                            <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">LikeME Faster(Business)</h2>
                                            <div>
                                                <div className="progress mt-3">
                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${dbuser ? dbuser.direct_team_business_per : 0}%` }} aria-valuenow={dbuser ? dbuser.direct_team_business_per : 0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>$ {dbuser ? dbuser.direct_team_business : 0}</p>
                                                    <p>$1000</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='row'>
                                        <div className='col-lg-8 col-md-12 col-12'>
                                            <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 rounded-xxl">
                                                <div className="card-body p-0">
                                                    <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">Like A Blast</h2>
                                                    <div>
                                                        <div className="progress mt-3" style={{ height: '0.5rem' }}>
                                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${dbuser ? (dbuser.d_team * 100 / 3) > 100 ? 100 : (dbuser.d_team * 100 / 3) : 0}%` }} aria-valuenow={dbuser ? (dbuser.d_team * 100 / 3) > 100 ? 100 : (dbuser.d_team * 100 / 3) : 0} aria-valuemin={0} aria-valuemax={100} />
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <p>{dbuser ? dbuser.d_team : 0}</p>
                                                            <p>3</p>
                                                        </div>
                                                        {dbuser ? dbuser.blast ?
                                                            <h1 className='text-center fw-700 pt-4'><span className='text-success'>Blast Achieved</span> </h1>
                                                            :
                                                            <h3 ><CountdownTimer targetDate={dbuser ? dbuser.createdAt10 : 0} /></h3> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12 col-12'>
                                            <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 rounded-xxl">
                                                <div className="card-body p-0">
                                                    <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">Directs</h2>
                                                    <div>
                                                        <h1>{dbuser ? dbuser.d_team : 0}</h1>
                                                    </div>
                                                    <br />
                                                    <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">Daily Blast</h2>
                                                    <div>
                                                        <h1>{dbuser ? dbuser.d_per : 0} %</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 rounded-xxl">
                                        <div className="card-body p-0">
                                            <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">LikeME Reward Capability (5x)</h2>
                                            <div>
                                                <div className="progress mt-3">
                                                    <div className="progress-bar bg-success" role="progressbar" title={`${dbuser ? dbuser.cap_per : 0} %`} style={{ width: `${dbuser ? dbuser.cap_per : 0}%` }} aria-valuenow={dbuser ? dbuser.cap_per : 0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>$ {dbuser ? Number(dbuser.cap_t_rewards).toFixed(2) : 0}</p>
                                                    <p>$ {dbuser ? Number(dbuser.cap_remain).toFixed(2) : 0}</p>
                                                    <p>$ {dbuser ? Number(dbuser.cap_5x).toFixed(2) : 0}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ps-2 pe-1">
                                        <h2 className="font-xl d-block mb-2 text-grey-900 fw-700">LikeME Statistics</h2>
                                        <div className="col-lg-3 pe-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#e5f6ff' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/total_stake.svg" width={25} className='me-3' alt="" />
                                                    {/* <i className="btn-round-lg d-inline-block me-3 bg-primary-gradiant feather-home font-md text-white" /> */}
                                                    <h4 className="text-primary font-xl fw-700">$ {dbuser ? dbuser.t_staking : 0} <span className="fw-bold mt-0 d-block text-grey  font-xssss">My Stake</span></h4>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-lg-3 pe-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#e5f6ff' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/directs.svg" width={25} className='me-3' alt="" />
                                                    <h4 className="text-primary font-xl fw-700"> {dbuser ? dbuser.d_team : 0}  <span className="fw-bold mt-0 d-block text-grey  font-xssss">Direct Team</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/team.svg" width={25} className='me-3' alt="" />
                                                    <h4 className="text-secondary font-xl fw-700"> {dbuser ? dbuser.team : 0} <span className="fw-bold mt-0 d-block text-grey  font-xssss">Total Team</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/team.svg" width={25} className='me-3' alt="" />
                                                    <h4 className="text-secondary font-xl fw-700"> {dbuser ? Number(dbuser.open_ancestry).toFixed(0) : 0} <span className="fw-bold mt-0 d-block text-grey  font-xssss">Open Ancestry</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/team.svg" width={25} className='me-3' alt="" />
                                                    <h4 className="text-secondary font-xl fw-700">$ {dbuser ? dbuser.team_business : 0} <span className="fw-bold mt-0 d-block text-grey  font-xssss">Total Business</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/team.svg" width={25} className='me-3' alt="" />
                                                    <h4 className="text-secondary font-xl fw-700">$ {dbuser ? dbuser.f_total - dbuser.f_given : 0} <span className="fw-bold mt-0 d-block text-grey  font-xssss">Freeze Expression</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/team.svg" width={25} className='me-3' alt="" />
                                                    <h4 className="text-secondary font-xl fw-700">$ {dbuser ? dbuser.f_given : 0} <span className="fw-bold mt-0 d-block text-grey  font-xssss">Release Expression</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/team.svg" width={25} className='me-3' alt="" />
                                                    <h4 className="text-secondary font-xl fw-700"> {dbuser ? dbuser.rank === null ? 'No Rank' : dbuser.rank : '-'} <span className="fw-bold mt-0 d-block text-grey  font-xssss">Rank</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/team.svg" width={25} className='me-3' alt="" />
                                                    <h4 className="text-secondary font-xl fw-700"> {dbuser ? dbuser.next_rank : '-'} <span className="fw-bold mt-0 d-block text-grey  font-xssss">Next Rank</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#e2f6e9' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/withdraw.svg" width={25} className='me-3' alt="" />
                                                    <h4 className="text-success font-xl fw-700">$ {dbuser ? dbuser.t_withdrawal : 0}  <span className="fw-bold mt-0 d-block text-grey  font-xssss">Withdrawal</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#fff0e9' }}>
                                                <div className="card-body d-flex p-0">
                                                    <img src="./assets/img/expression_rank.svg" width={25} className='me-3' alt="" />
                                                    <h4 className="text-warning font-xl fw-700">$ {dbuser ? dbuser.pen_withdrawal : 0}  <span className="fw-bold mt-0 d-block text-grey  font-xssss">Pending Withdrawal</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        {dbuser ? dbuser.rank_number === 0 ?
                                            <>
                                                <div className="col-lg-4 pe-2">
                                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#e5f6ff' }}>
                                                        <div className="card-body d-flex p-0">
                                                            <img src="./assets/img/team_abc.svg" width={25} className='me-3' alt="" />
                                                            <h4 className="text-primary font-xl fw-700">$ {dbuser ? dbuser.legA : 0} <span className="fw-bold mt-0 d-block text-grey  font-xssss">A Team</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 pe-2 ps-2">
                                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                        <div className="card-body d-flex p-0">
                                                            <img src="./assets/img/team_abc.svg" width={25} className='me-3' alt="" />
                                                            <h4 className="text-secondary font-xl fw-700">$ {dbuser ? dbuser.legB : 0}  <span className="fw-bold mt-0 d-block text-grey  font-xssss">B Team</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 ps-2">
                                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#fff0e9' }}>
                                                        <div className="card-body d-flex p-0">
                                                            <img src="./assets/img/team_abc.svg" width={25} className='me-3' alt="" />
                                                            <h4 className="text-warning font-xl fw-700">$ {dbuser ? dbuser.legC : 0}  <span className="fw-bold mt-0 d-block text-grey  font-xssss">Other Team</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> :
                                            <>
                                                <div className="col-lg-4 pe-2">
                                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#e5f6ff' }}>
                                                        <div className="card-body d-flex p-0">
                                                            <img src="./assets/img/team_abc.svg" width={25} className='me-3' alt="" />
                                                            <h4 className="text-primary font-xl fw-700"> {dbuser ? dbuser.legAr : 0} <span className="fw-bold mt-0 d-block text-grey  font-xssss">A Team {dbuser.rank}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 pe-2 ps-2">
                                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                        <div className="card-body d-flex p-0">
                                                            <img src="./assets/img/team_abc.svg" width={25} className='me-3' alt="" />
                                                            <h4 className="text-secondary font-xl fw-700"> {dbuser ? dbuser.legBr : 0}  <span className="fw-bold mt-0 d-block text-grey  font-xssss">B Team {dbuser.rank}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 ps-2">
                                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#fff0e9' }}>
                                                        <div className="card-body d-flex p-0">
                                                            <img src="./assets/img/team_abc.svg" width={25} className='me-3' alt="" />
                                                            <h4 className="text-warning font-xl fw-700">{dbuser ? dbuser.legOr : 0}  <span className="fw-bold mt-0 d-block text-grey  font-xssss">Other Team {dbuser.rank}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : ''}
                                        {dbuser ? dbuser.rank_number === 0 ?
                                            <>
                                                <div className="col-lg-4 pe-2 ps-2">
                                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#f6f3ff' }}>
                                                        <div className="card-body d-flex p-0">
                                                            <img src="./assets/img/r_team_abc.svg" width={25} className='me-3' alt="" />
                                                            <h4 className="text-secondary font-xl fw-700">$ {dbuser ? dbuser.r_legA : 0}   <span className="fw-bold mt-0 d-block text-grey  font-xssss">Req. A Team</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 pe-2 ps-2">
                                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#e2f6e9' }}>
                                                        <div className="card-body d-flex p-0">
                                                            <img src="./assets/img/r_team_abc.svg" width={25} className='me-3' alt="" />
                                                            <h4 className="text-success font-xl fw-700">$ {dbuser ? dbuser.r_legB : 0} <span className="fw-bold mt-0 d-block text-grey  font-xssss">Req. B Team</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 ps-2">
                                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ backgroundColor: '#fff0e9' }}>
                                                        <div className="card-body d-flex p-0">
                                                            <img src="./assets/img/r_team_abc.svg" width={25} className='me-3' alt="" />
                                                            <h4 className="text-warning font-xl fw-700">$ {dbuser ? dbuser.r_legC : 0}   <span className="fw-bold mt-0 d-block text-grey  font-xssss">Req. Other Team</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : '' : ''}
                                    </div>
                                </div>
                                <h2 className="font-xl d-block mb-2 text-grey-900 fw-700">LikeME Believe</h2>

                                <div className="col-lg-4">
                                    <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 rounded-xxl">
                                        <div className="card-body p-0">
                                            <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">Month-1</h2>
                                            <div className='d-flex justify-content-between'>
                                                <p>Achieved</p>
                                                <p>Required</p>
                                            </div>
                                            <div>
                                                <div className="progress mt-3" style={{ height: '0.5rem' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${dbuser ? dbuser.legA * 100 / 5000 : 0}%` }} aria-valuenow={dbuser ? dbuser.legA * 100 / 5000 : 0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>${dbuser ? dbuser.legA : 0}</p>
                                                    <p>$5000</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="progress mt-3" style={{ height: '0.5rem' }}>
                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${dbuser ? dbuser.legB * 100 / 5000 : 0}%` }} aria-valuenow={dbuser ? dbuser.legB * 100 / 5000 : 0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>${dbuser ? dbuser.legB : 0}</p>
                                                    <p>$5000</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="progress mt-3" style={{ height: '0.5rem' }}>
                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${dbuser ? dbuser.legC * 100 / 5000 : 0}%` }} aria-valuenow={dbuser ? dbuser.legC * 100 / 5000 : 0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>${dbuser ? dbuser.legC : 0}</p>
                                                    <p>$5000</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 rounded-xxl">
                                        <div className="card-body p-0">
                                            <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">Month-2</h2>
                                            <div className='d-flex justify-content-between'>
                                                <p>Achieved</p>
                                                <p>Required</p>
                                            </div>
                                            <div>
                                                <div className="progress mt-3" style={{ height: '0.5rem' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>$0</p>
                                                    <p>$5000</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="progress mt-3" style={{ height: '0.5rem' }}>
                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>$0</p>
                                                    <p>$5000</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="progress mt-3" style={{ height: '0.5rem' }}>
                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>$0</p>
                                                    <p>$5000</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 rounded-xxl">
                                        <div className="card-body p-0">
                                            <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">Month-3</h2>
                                            <div className='d-flex justify-content-between'>
                                                <p>Achieved</p>
                                                <p>Required</p>
                                            </div>
                                            <div>
                                                <div className="progress mt-3" style={{ height: '0.5rem' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>$0</p>
                                                    <p>$5000</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="progress mt-3" style={{ height: '0.5rem' }}>
                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>$0</p>
                                                    <p>$5000</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="progress mt-3" style={{ height: '0.5rem' }}>
                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p>$0</p>
                                                    <p>$5000</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
