import React, { useContext, useEffect, useState } from 'react'
import Header from '../comman/Header'
import LeftSidebar from '../comman/LeftSidebar'
import { Link, useNavigate } from 'react-router-dom';
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';

export default function Profile() {
    const { pkgs, dbuser, account, getudata, connectMetamask, esigner, formatAddress, library, toastError, settings, ethers, toastSuccess, getuserDB } = useContext(ProjectContext)

    const [showPackage, setShowPackage] = useState(false);
    const [spkg, setspkg] = useState(null);
    const [amount, setamount] = useState(0);
    const [trdone, settrdone] = useState(false);
    const [tandc, settandc] = useState(false);
    const [hash, sethash] = useState("");
    const [referral, setreferral] = useState("");
    var navigate = useNavigate();

    const onDeposit = async () => {
        settrdone(true);
        sethash("");
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!referral) {
            toastError("Enter Referral Address");
            settrdone(false);
            return;
        }
        if (dbuser === null) {
            var data = await getudata(referral);
            if (data.data.error) {
                toastError("Invalid Referral Address");
                settrdone(false);
                return "";
            }
            if (data.data.data.status === false) {
                toastError("Referral not active");
                settrdone(false);
                return "";
            }
        }

        console.log("dassd", amount);

        if (!spkg) {
            toastError("Select Package !");
            settrdone(false);
            return;
        }
        // if (!tandc) {
        //     toastError("Accept terms and conditions");
        //     settrdone(false);
        //     return;
        // }

        let contract = new library.Contract(
            settings.contract,
            JSON.parse(settings.contract_abi),
            esigner
        );
        let token_contract = new library.Contract(
            settings.token,
            JSON.parse(settings.token_abi),
            esigner
        );
        const allowance = await token_contract.allowance(
            account,
            settings.contract
        );

        async function main() {
            // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
            //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
            //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
            //   );
            try {
                // var stk = await contract.stake(
                //     ethers.utils.parseEther(`${amount}`),
                //     referral
                // );
                const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

                // const receipt = await stk.wait();
                // sethash(receipt.transactionHash);
                sethash(randomHash);
                toastSuccess("Transaction done");
                await axios
                    .post(process.env.REACT_APP_BACKEND_LINK, {
                        method: "transaction",
                        submethod: "insert",
                        key: process.env.REACT_APP_KEY,
                        address: account,
                        ref_address: referral,
                        pkg_id: spkg._id,
                        hash: randomHash,
                        // hash: receipt.transactionHash,
                        token: spkg.amount,
                        amount: spkg.amount,
                        rate: 1,
                    })
                    .then((res) => {
                        console.log("res", res.data);
                        // var dedata = decryptData(res.data);
                        if (res.data.error) {
                            return "";
                        }
                    });
                getuserDB();
                settrdone(false);
                navigate("/dashboard");

            } catch (error) {
                console.log("Error", error);
                toastError("Transaction declined");
                settrdone(false);
                return;
            }

        }
        // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
        main()
        return
        if (Number(ethers.utils.formatEther(allowance)) < amount) {
            // if (false) {
            try {
                var approv = await token_contract.approve(
                    settings.contract,
                    ethers.utils.parseEther("100000000")
                );
                const receipt = await approv.wait();
                console.log(receipt);
                toastSuccess("Token Approved");
                main();
            } catch (error) {
                console.log("error", error);
                toastError("Token not approved");
                settrdone(false);
                return;
            }
        } else {
            main();
        }
    };
    useEffect(() => {
        if (dbuser !== null) {
            if (dbuser.ref_address !== null) {
                setreferral(dbuser.ref_address);
            }
        }
    }, [dbuser, account]);


    return (
        <>
            <div className="main-wrapper">
                <Header />
                {/* <LeftSidebar /> */}
                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/dashboard" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white" /></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Profile</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-4 text-center">
                                                {/* <figure className="avatar ms-auto me-auto mb-0 mt-2 w100"><img src="images/pt-1.jpg" alt="image" className="shadow-sm rounded-3 w-100" /></figure> */}
                                                <h2 className="fw-700 font-sm text-grey-900 my-3">LikeME User</h2>
                                                {/* <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">Brooklyn</h4> */}
                                                {/* <a href="#" class="p-3 alert-primary text-primary font-xsss fw-500 mt-2 rounded-3">Upload New Photo</a> */}
                                            </div>
                                        </div>
                                        <form action="#">
                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss">First Name</label>
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss">Email</label>
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss">Phone</label>
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss">Town / City</label>
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <a href="#" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}