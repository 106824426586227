import React from 'react'

export default function Table() {
    return (
        <>
            <div className="table-container mt-5">
                <table id="mytable" className="table align-middle mb-0">
                    <thead className="">
                        <tr className="header-row">
                            <th>ID #</th>
                            <th>Task Name</th>
                            <th>Assignee</th>
                            <th>Process</th>
                            <th>Due</th>
                            <th />
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className>
                                        <p className="fw-bold mb-1">12</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p className="fw-bold fw-normal mb-1">HR Request</p>
                            </td>

                            <td>
                                <span><a className="btn avatar-button rounded-circle overflow-hidden p-0 m-0 d-inline-flex"><span data-v-0a07f886 className="avatar-span border-0 d-inline-flex align-items-center justify-content-center text-white text-uppercase text-nowrap font-weight-normal" /></a>
                                    {/**/}</span>Jeff Kingly
                            </td>
                            <td>Human Resources</td>
                            <td>
                                <div className>
                                    <span className>01/2/2023</span>
                                    <p className="time text-muted mb-0">
                                        06:30 <span className="fw-bold">am</span>
                                    </p>
                                </div>
                            </td>
                            <td>
                                <button type="button" className="btn btn-sm btn-rounded text-primary">
                                    <i className="me-1 action-icon bi bi-file-earmark-richtext text-primary" />
                                    Preview Form
                                </button>
                            </td>
                            <td>
                                <span className="badge badge-success rounded-pill d-inline">In Progress</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className>
                                        <p className="fw-bold mb-1">2</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p className="fw-bold fw-normal mb-1">IT Support Ticket</p>
                            </td>
                            <td>
                                <span><a className="btn avatar-button rounded-circle overflow-hidden p-0 m-0 d-inline-flex"><span data-v-0a07f886 className="avatar-span border-0 d-inline-flex align-items-center justify-content-center text-white text-uppercase text-nowrap font-weight-normal" /></a>
                                    {/**/}</span>
                                Alex Sabonous
                            </td>
                            <td>IT Ticketing</td>
                            <td>
                                <div className>
                                    <span className>11/22/2022</span>
                                    <p className="time text-muted mb-0">
                                        03:30 <span className="fw-bold">pm</span>
                                    </p>
                                </div>
                            </td>
                            <td>
                                <button type="button" className="btn btn-sm btn-rounded text-primary">
                                    <i className="me-1 action-icon bi bi-file-earmark-richtext text-primary" />
                                    Preview Form
                                </button>
                            </td>
                            <td>
                                <span className="badge badge-warning rounded-pill d-inline">Overdue</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className>
                                        <p className="fw-bold mb-1">27</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p className="fw-bold fw-normal mb-1">Form Task</p>
                            </td>
                            <td>
                                <span><a className="btn avatar-button rounded-circle overflow-hidden p-0 m-0 d-inline-flex"><span data-v-0a07f886 className="avatar-span border-0 d-inline-flex align-items-center justify-content-center text-white text-uppercase text-nowrap font-weight-normal" /></a>
                                    {/**/}</span>
                                Stan Vanlchek
                            </td>
                            <td>Routing Process #21G</td>
                            <td>
                                <div className>
                                    <span className>10/15/2022</span>
                                    <p className="time text-muted mb-0">
                                        10:22 <span className="fw-bold">pm</span>
                                    </p>
                                </div>
                            </td>
                            <td>
                                <button type="button" className="btn btn-sm btn-rounded text-primary">
                                    <i className="me-1 action-icon bi bi-file-earmark-richtext text-primary" />
                                    Preview Form
                                </button>
                            </td>
                            <td>
                                <span className="badge badge-primary rounded-pill d-inline">Open</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className>
                                        <p className="fw-bold mb-1">65</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p className="fw-bold fw-normal mb-1">Payroll Automation</p>
                            </td>

                            <td>
                                <span><a className="btn avatar-button rounded-circle overflow-hidden p-0 m-0 d-inline-flex"><span data-v-0a07f886 className="avatar-span border-0 d-inline-flex align-items-center justify-content-center text-white text-uppercase text-nowrap font-weight-normal" /></a>
                                    {/**/}</span>Jeff Kingly
                            </td>
                            <td>Routing Process #114F</td>
                            <td>
                                <div className>
                                    <span className>12/02/2022</span>
                                    <p className="time text-muted mb-0">
                                        2:00 <span className="fw-bold">pm</span>
                                    </p>
                                </div>
                            </td>
                            <td>
                                <button type="button" className="btn btn-sm btn-rounded text-primary">
                                    <i className="me-1 action-icon bi bi-file-earmark-richtext text-primary" />
                                    Preview Form
                                </button>
                            </td>
                            <td>
                                <span className="badge badge-danger rounded-pill d-inline">Expired</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <nav className="mt-4">
                    <ul className="pagination justify-content-center">
                        <li className="page-item disabled">
                            <a className="page-link">Previous</a>
                        </li>
                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item">
                            <a className="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}
