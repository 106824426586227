import React, { useState } from 'react'
import Header from '../comman/Header'
import LeftSidebar from '../comman/LeftSidebar'
import Table from './Table'
import Pagination from '../comman/Pagination'

export default function Reward() {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [level, setlevel] = useState(1);

    return (
        <>
            <div className="main-wrapper">
                <Header />
                {/* <LeftSidebar /> */}
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{ maxWidth: '100%' }}>
                            <div className="middle-wrap-2">
                                <div className="card-body px-4 w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3">
                                    <div className='d-flex'>
                                        <a href="/" className="d-inline-block"><i className="ti-arrow-left font-sm text-white" /></a>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0">Table</h4>
                                    </div>
                                    <form action="#" className="float-left header-search mb-3">
                                        <div className="form-group mb-0 icon-input">
                                            <i className="feather-search font-sm text-grey-400" />
                                            <input type="text" placeholder="Start typing to search.." className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg" />
                                        </div>
                                    </form>
                                </div>
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4 overflow-auto">
                                    <div className="card-body px-lg-5 px-4 w-100 border-0 mb-0">
                                        <div className="table-container mt-5">
                                            <table id="mytable" className="table align-middle mb-0">
                                                <thead className="">
                                                    <tr className="header-row">
                                                        <th>ID #</th>
                                                        <th>Task Name</th>
                                                        <th>Assignee</th>
                                                        <th>Process</th>
                                                        <th>Due</th>
                                                        <th />
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className>
                                                                    <p className="fw-bold mb-1">12</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="fw-bold fw-normal mb-1">HR Request</p>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                <a className="btn avatar-button rounded-circle overflow-hidden p-0 m-0 d-inline-flex">
                                                                    <span data-v-0a07f886 className="avatar-span border-0 d-inline-flex align-items-center justify-content-center text-white text-uppercase text-nowrap font-weight-normal" />
                                                                </a>
                                                            </span>
                                                            Jeff Kingly
                                                        </td>
                                                        <td>Human Resources</td>
                                                        <td>
                                                            <div className>
                                                                <span className>01/2/2023</span>
                                                                <p className="time text-muted mb-0">
                                                                    06:30 <span className="fw-bold">am</span>
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-rounded text-primary">
                                                                <i className="me-1 action-icon bi bi-file-earmark-richtext text-primary" />
                                                                Preview Form
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <span className="badge badge-success rounded-pill d-inline">In Progress</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <Pagination
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageSize={pageSize}
                                                setPageSize={setPageSize}
                                                pages={pages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
