import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { ProjectContext } from '../../ProjectContext';
export default function Header() {
    const { account, connectMetamask, disconnectNow, formatAddress } = useContext(ProjectContext);
    const [showProfile, setShowProfile] = useState();
    const history = useNavigate()
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode ? JSON.parse(savedMode) : false;
    });

    // Update body class and localStorage whenever isDarkMode changes
    useEffect(() => {
        if (isDarkMode) {
            // document.body.classList.add('dark-mode');
            document.body.classList.remove('theme-dark');
        } else {
            document.body.classList.add('theme-dark');
            // document.body.classList.remove('dark-mode');
        }
        localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
    }, [isDarkMode]);

    // Toggle dark mode
    const toggleDarkMode = () => {
        setIsDarkMode(prevMode => !prevMode);
    };
    const logout = async () => {
        disconnectNow()
        history('/')
    }
    const [showSidebar, setShowSidebar] = useState(false);
    return (
        <>
            <div className="nav-header bg-white shadow-xs border-0 justify-content-between">
                <div className="nav-top d-flex justify-content-between">
                    {isDarkMode ?
                        <Link to="/"><img src="../assets/img/likeme_logo_dark.svg" alt="" width={150} /></Link>
                        :
                        <Link to="/"><img src="../assets/img/likeme_logo_white.svg" alt="" width={150} /></Link>
                    }
                    <button className="nav-menu me-0 ms-2" onClick={() => setShowSidebar(!showSidebar)} />
                </div>
                {account ?
                    <>

                        <div className="p-2 text-center ms-3 position-relative dropdown-menu-icon menu-icon cursor-pointer">
                            <img src="../assets/images/profile-4.png" alt="user" className="w40 mt--1" onClick={() => setShowProfile(!showProfile)} />
                            {/* <i className="feather-settings animation-spin d-inline-block font-xl text-current" /> */}
                            <div className={showProfile ? "dropdown-menu-settings switchcolor-wrap active" : "dropdown-menu-settings switchcolor-wrap"}>
                                <h4 className="fw-700 font-sm mb-4">Profile</h4>
                                <h6 className="font-xssss text-grey-500 fw-700 mb-3 d-block">Conected Address</h6>
                                <p>{formatAddress(account)} <i className='ti-clipboard' /></p>

                                {/* <div className="card bg-transparent-card border-0 d-block mt-3">
                            <h4 className="d-inline font-xssss mont-font fw-700">Menu Position</h4>
                            <div className="d-inline float-right mt-1">
                            <label className="toggle toggle-menu"><input type="checkbox" /><span className="toggle-icon" /></label>
                            </div>
                            </div> */}
                                <div className="card bg-transparent-card border-0 d-block mt-3">
                                    <h4 className="d-inline font-xssss mont-font fw-700">Light Mode</h4>
                                    <div className="d-inline float-right mt-1">
                                        <label className="toggle toggle-dark"><input type="checkbox" onClick={toggleDarkMode} checked={isDarkMode} /><span className="toggle-icon" /></label>
                                    </div>
                                </div>
                                <div className="card bg-transparent-card border-0 d-block mt-3" onClick={logout}>
                                    <h4 className="d-inline font-xssss mont-font fw-700">Log out</h4>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <a type='button' className="p-0 ms-3 menu-icon" onClick={connectMetamask}><img src="../assets/images/profile-4.png" alt="user" className="w40 mt--1" /></a>
                }
                {/* <a href="default-settings.html" className="p-0 ms-3 menu-icon"><img src="../assets/images/profile-4.png" alt="user" className="w40 mt--1" /></a> */}
            </div>
            <nav className={showSidebar ? "navigation scroll-bar nav-active" : "navigation scroll-bar"}>

                <div className="container ps-0 pe-0">
                    <div className="nav-content">
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span>LikeME </span>Stakings</div>
                            <ul className="mb-3">
                                <li><Link to="/dashboard" className="nav-content-bttn open-font"><img src="../assets/img/dashboard.svg" alt="dashboard" width={20} className='me-3' srcset="" /> <span>Dashboard</span></Link></li>
                                <li><Link to="/staking" className="nav-content-bttn open-font"><img src="../assets/img/stake.svg" alt="dashboard" width={20} className='me-3' srcset="" /> <span>Staking</span></Link></li>
                                <li><Link to="/withdrawal" className="nav-content-bttn open-font"><img src="../assets/img/withdraw.svg" alt="dashboard" width={20} className='me-3' srcset="" /> <span>Withdraw</span></Link></li>
                                <li><Link to="/withdrawal-history" className="nav-content-bttn open-font"><img src="../assets/img/withdraw.svg" alt="dashboard" width={20} className='me-3' srcset="" /> <span>Withdraw History</span></Link></li>
                                <li><Link to="/team" className="nav-content-bttn open-font"><img src="../assets/img/team.svg" alt="dashboard" width={20} className='me-3' srcset="" /> <span>Team</span></Link></li>
                            </ul>
                        </div>
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span>LikeME </span>Rewards</div>
                            <ul className="mb-1 top-content">
                                <li className="logo d-none d-xl-block d-lg-block" />
                                <li><Link to="/blast" className="nav-content-bttn open-font"><img src="../assets/img/blast.svg" alt="dashboard" width={20} className='me-3' srcset="" /><span>Blast</span></Link></li>
                                <li><Link to="/faster" className="nav-content-bttn open-font"><img src="../assets/img/faster.svg" alt="dashboard" width={20} className='me-3' srcset="" /><span>Faster</span></Link></li>
                                <li><Link to="/ancestory" className="nav-content-bttn open-font"><img src="../assets/img/ancesty.svg" alt="dashboard" width={20} className='me-3' srcset="" /><span>Ancestry</span></Link></li>
                                <li><Link to="/expression" className="nav-content-bttn open-font"><img src="../assets/img/expression.svg" alt="dashboard" width={20} className='me-3' srcset="" /><span>Expression </span></Link></li>
                                <li><Link to="/believe" className="nav-content-bttn open-font"><img src="../assets/img/believe.svg" alt="dashboard" width={20} className='me-3' srcset="" /><span>Believe </span></Link></li>
                            </ul>
                        </div>

                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span /> Account</div>
                            <ul className="mb-1">
                                <li className="logo d-none d-xl-block d-lg-block" />
                                <li><Link to="/profile" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-settings me-3 text-grey-500" /><span>Profile</span></Link></li>
                                <li><a href="" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-pie-chart me-3 text-grey-500" /><span>Presentation</span></a></li>
                                <li><a href='#' onClick={logout} className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-log-out me-3 text-grey-500" /><span>Logout</span></a></li>
                                {/* <li><a href="default-message.html" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-message-square me-3 text-grey-500" /><span>Chat</span></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}
