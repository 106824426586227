import React, { useContext, useEffect, useState } from 'react'
import Header from '../comman/Header'
import Pagination from '../comman/Pagination'
import { ProjectContext } from '../../ProjectContext'
import axios from 'axios';
import { Link } from 'react-router-dom'

export default function Team() {
    const { account, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [level, setlevel] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "email",
                submethod: "level",
                // address: "0xfded9e02beccedbddf19a1cf3a86769fca363427",
                address: account,
                level: level,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    setdata([]);
                    setPages(1);
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, level,pageSize, currentPage]);


    return (
        <>
            <div className="main-wrapper">
                <Header />
                {/* <LeftSidebar /> */}
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{ maxWidth: '100%' }}>
                            <div className="middle-wrap-2">
                                <div className="card-body px-4 w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3">
                                    <div className='d-flex'>
                                        <Link to="/dashboard" className="d-inline-block"><i className="ti-arrow-left font-sm text-white" /></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0">Team</h4>
                                    </div>
                                    <form action="#" className="float-left header-search mb-3">
                                        <div className="form-group mb-0 icon-input">
                                            <i className="feather-search font-sm text-grey-400" />
                                            <input type="text" className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg" placeholder="Enter level" value={level}
                                            onChange={(e) => setlevel(e.target.value)}/>
                                        </div>
                                    </form>
                                </div>
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4 overflow-auto">
                                    <div className="card-body px-lg-5 px-4 w-100 border-0 mb-0">
                                        <div className="table-container mt-5">
                                            <table id="mytable" className="table align-middle mb-0">
                                                <thead className="">
                                                    <tr className="header-row">
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Address</th>
                                                        <th>Mentor Address</th>
                                                        <th>Staking</th>
                                                        <th>Team Business</th>
                                                        <th>Direct Business</th>
                                                        <th>Direct Team</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="text-center">
                                                        {isLoading ? "Data is loading" : ""}
                                                    </tr>
                                                    {!isLoading ? (
                                                        !data.length ? (
                                                            <tr className="text-center">
                                                                <td className="text-center" colSpan={9}>
                                                                    <span className="w-100">No data found</span>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            ""
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                    {data.map((ele, i) => {
                                                        return (
                                                            <tr>
                                                                <td >
                                                                    {ele.id}
                                                                </td>

                                                                <td >
                                                                    {ele.createdAt}
                                                                </td>
                                                                <td >
                                                                    <span onClick={() => copyaddress(ele.address)}>
                                                                        {formatAddress(ele.address)}{" "}
                                                                        <i className="fa fa-copy"></i>
                                                                    </span>
                                                                </td>
                                                                <td >
                                                                    <span onClick={() => copyaddress(ele.ref_address)}>
                                                                        {formatAddress(ele.ref_address)}{" "}
                                                                        <i className="fa fa-copy"></i>
                                                                    </span>
                                                                </td>
                                                                <td >
                                                                    $ {ele.t_staking}
                                                                </td>
                                                                <td >
                                                                    $ {ele.team_business}
                                                                </td>
                                                                <td >
                                                                    $ {ele.direct_team_business}
                                                                </td>
                                                                <td >
                                                                     {ele.d_team} 
                                                                </td>
                                                             
                                                                <td>
                                                                    {!ele.status ?
                                                                        <span className="badge badge-danger rounded-pill d-inline">Not Active</span> : <span className="badge badge-success rounded-pill d-inline">Active</span>}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            <Pagination
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageSize={pageSize}
                                                setPageSize={setPageSize}
                                                pages={pages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
