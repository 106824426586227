import React, { useContext, useEffect, useState } from 'react'
import { ProjectContext } from '../ProjectContext'
import { useNavigate } from 'react-router-dom'

export default function Login() {
    const { account, connectMetamask ,toastError} = useContext(ProjectContext)
    const history = useNavigate()
    const [tandc, settandc] = useState(false);

    useEffect(() => {
        if (account) {
            history('/dashboard')
        }
    }, [account])
    return (
        <>
            <div className="main-wrap">
                <div className="nav-header bg-transparent shadow-none border-0">
                    <div className="nav-top w-100 justify-content-start">
                        <img src="./assets/img/logo192.png" alt="" width={50} /><span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">LikeMe. </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat" style={{ backgroundImage: 'url(assets/images/login-bg-2.jpg)' }} />
                    <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                        <div className="card shadow-none border-0 ms-auto me-auto login-card">
                            <div className="card-body rounded-0 text-center">
                                <h2 className="fw-700 display1-size display2-md-size mb-4">Welcome To <br /> Web3 Social Media</h2>
                                <form>

                                    <div className="form-check text-left mb-3">
                                        <input type="checkbox" className="form-check-input mt-2" id="logincheck" onChange={(event) => settandc(event.target.checked)} />
                                        <label className="form-check-label font-xsss text-grey-500" htmlFor="logincheck">Accept Term and Conditions</label>
                                    </div>
                                </form>
                                <div className="col-sm-12 p-0 text-left">
                                    <div className="form-group mb-1"><a href="#" className="form-control text-center style2-input text-white fw-600 bg-metamask border-0 p-0 " onClick={() => {tandc ?connectMetamask():toastError("Please accept terms and conditions")}}>Connect Wallet</a></div>
                                    {/* <div className="form-group mb-1"><a href="#" className="form-control text-center style2-input text-white fw-600 bg-metamask border-0 p-0 " >Connect Wallet</a></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
